import React from "react";
import { Helmet } from "react-helmet";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import axios from "axios";

// mui icon and svg icon
import CancelIcon from "@mui/icons-material/Cancel";
import Warning from "images/user-dashboard/warning-icon.svg";
import Warningyellow from "images/user-dashboard/warning-yellow.svg";
import Tickicon from "images/user-dashboard/tick-icon.svg";
import LoadPassword from "images/load_password.svg"
import { connect } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { HiDotsHorizontal, HiOutlineArrowNarrowDown, HiOutlineSearch } from "react-icons/hi";
import { styled } from "@mui/material/styles";

// mui components
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import TableBody from "@mui/material/TableBody";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  CardContent,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Checkbox,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
  Paper,
  Backdrop,
  InputLabel,
} from "@mui/material";

// scss file
import "./vendorlist.scss";

// import components
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";
import AlertMessage from "components/message/message";
import SetPageLimt from "redux/actions/SetPageLimit";
import UserProfileAction from "redux/actions/UserProfielAction";
import TimerButton from "utils/TimerButton";
import Pagination from "components/pagination/pagination";
import { ContainerLoader } from "components/common/ContainerLoader";
import Cookies from "js-cookie";
import UploadCSVTimerButton from "utils/UploadCSVTimerButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary["dark"],
    color: theme.palette.common.white,
    fontFamily: "Barlow !important",
    fontWeight: "bold",
    textAlign: "left",
    position: "relative",
    // width: "100%",
    minWidth: "max-content",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Barlow !important",
    textAlign: "left",
    color: "#3C4E6A",
    borderBottom: "1px solid #CCCCCC",
    width: "auto",
    padding: "12px 15px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class Vendorlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidepassword: false,
      loadpass: false,
      open: false,
      message: "",
      successError: "",
      vendorList: [],
      loader: false,
      isSortingLoader: false,
      searchPage: 0,
      uploadBulkCsvDate: null,
      totalRecords: 0,
      filterData: {
        account_type: ["Vendor"],
        skip: 0,
        limit: 5,
        league: "",
        team: "",
        username: "",
        status: "",
        sortBy: "",
        sortOrder: "",
      },
      usersData: [],
      userfilter: "",
      dataLists: [],
      indexNum: "",
      modalShow: false,
      deleteId: "",
      bulkuploading: false,
      scanvendors: [],
      syncvendors: [],
      csvdata: [],
      resultcsvdata: [],
      syncshow: false,
      refreshshow: false,
      syncCount: 0,
      refreshCount: 0,
      //sorting data 
      currentColumn: '',
      activeasc: '',
      activedesc: '',
      styleInactive: false,
      direction: '',
      sortBy: null,
      sortOrder: null,



    };
    this.onPageChanged = this.onPageChanged.bind(this);
    this.SearchDisable = this.SearchDisable.bind(this);
    this.handleSelectSortByCreatedOnChange = this.handleSelectSortByCreatedOnChange.bind(this);
    this.divRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ loader: true, uploadBulkCsvDate: this.props.UserProfile.data.nextAddBulkCsv }, () => {
      this.getUserDetail();
    });
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('page'), 10) ?? 1;
    if (page) {
      const { filterData } = this.state;
      filterData["skip"] = page;
      this.setState({ filterData })
    }
  }


  async vendor_list(msg, type = "success") {
    const vendorList_api_url =
      process.env.REACT_APP_API_URL + "/api/account/getAllAccounts";
    const paramas = {
      ...this.state.filterData,
      username: this.state.userfilter,
      limit: parseInt(this.props.limit),
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder
    }
    try {
      const response = await axios.post(
        vendorList_api_url,
        paramas,
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      );

      const { data } = response;
      if (data.tokenExpired && data.error) {
        this.setState({
          open: true,
          message: data.message,
          successError: "error",
        });
        Cookies.remove('at')
        Cookies.remove('accessToken')
        this.props.history.push("/login")
      } else if (data.error) {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      } else {
        this.setState({ totalRecords: data.count });
        this.setState(
          {
            vendorList: data,
            dataLists: data.data,
            open: msg === "" ? false : true,
            message: msg,
            successError: type,
          },

          () => {
            this.filterSearch();
          }
        );
        this.setState({ loader: false, isSortingLoader: false });
      }
    } catch (error) {
      this.setState({
        loader: false,
        open: true,
        message: "Something went wrong",
        successError: "error",
      });
    }
  }

  getUserDetail() {
    const { filterData } = this.state;
    filterData["limit"] = this.props.limit
    this.setState({ filterData }, () => {
      this.vendor_list("");
    });
  }

  updateProfile() {
    this.setState({ loader: true }, () => {
      const updateDetail_api_url =
        process.env.REACT_APP_API_URL + "/api/user/updateUser";
      axios
        .post(
          updateDetail_api_url,
          { per_page_limit: this.state.filterData.limit },
          {
            headers: {
              authorization: "Bearer " + Cookies.get("accessToken"),
            },
          }
        )
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.props.getUserProfile();
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "success",
            });
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
        });
    });
  }

  hiddenPassword = (itemval) => {
    if (this.state.hidepassword && this.state.indexNum === itemval.id) {
      this.setState({ hidepassword: false, indexNum: "", loadpass: false });
    } else {
      this.setState(
        { hidepassword: true, loadpass: true, indexNum: itemval.id },
        () => {
          const pass_api_url =
            process.env.REACT_APP_API_URL +
            "/api/account/accountDetailWithPlainPass";
          axios
            .post(
              pass_api_url,
              { id: itemval.id },
              {
                headers: {
                  authorization:
                    "Bearer " + Cookies.get("accessToken"),
                },
              }
            )
            .then((response) => {
              const { data } = response;
              if (data.error) {
                this.setState({
                  hidepassword: false,
                  indexNum: "",
                  loadpass: false,
                });
              } else {
                const { dataLists } = this.state;
                var index = dataLists.map((v) => { return v.id }).indexOf(itemval.id);
                dataLists[index]["password"] = data.data.password;
                this.setState({ loadpass: false, dataLists });
              }
            })
            .catch((error) => {
              this.setState({
                hidepassword: false,
                indexNum: "",
                loadpass: false,
              });
            });
        }
      );
    }
  };
  setModalShow = (id) => {
    this.setState({
      modalShow: true,
      deleteId: id,
    });
  };
  onHideSync = () => {
    this.setState({
      syncshow: false,
    });
  };
  onHideRefresh = () => {
    this.setState({
      refreshshow: false,
    });
  };

  onHide = () => {
    this.setState({
      modalShow: false,
    });
  };
  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };

  scanAPiCall = async (data) => {
    const param = {
      id: data.id,
    };
    this.setState({
      loader: true,
    });

    const vendorEventScan_api_url =
      process.env.REACT_APP_API_URL + "/api/account/scanVendorEvent";
    axios
      .post(vendorEventScan_api_url, param, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        // Get All data
        const success_message = "Vendor Data has been updated";
        this.vendor_list(success_message);
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });

    /*
        const vendorListScan_api_url = "https://d1wzmy4qssla32.cloudfront.net/api/user/scanAccount"
        await axios.post(vendorListScan_api_url, param,  { headers: { authorization: 'Bearer '+ Cookies.get('accessToken')} })
            .then((response) => {
                console.log("Scan APi Response", response);
                const { data } = response;
                if (data.error) {   
                    this.setState({
                        loader: false,
                        open:true,
                        message:response.data.message,
                        successError:'error'
                    })
                }
                else {

                    if (data.success) {
                        
                    }
                    else {
                        this.setState({
                            loader: false,
                            open: true,
                            message: "Something went wrong",
                            successError: 'error',
                        })
                    }
                }
            }).catch((error) => {
                this.setState({
                    loader: false,
                    open:true,
                    message:"Something went wrong",
                    successError:'error',
                })
            })
        */
  };

  refreshAPiCall = async (data) => {
    const param = { id: data.id };
    this.setState({ loader: true });
    try {
      const vendorEventScan_api_url =
        process.env.REACT_APP_API_URL + "/api/account/refreshVendorEvent";
      await axios.post(vendorEventScan_api_url, param, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      });
      this.vendor_list(
        "Event refresh successfully, hit scan to get latest data"
      );
    } catch (error) {
      this.setState({
        loader: false,
        open: true,
        message: "Something went wrong",
        successError: "error",
      });
    }
  };

  callDelete = async () => {
    this.setState({
      modalShow: false,
    });
    const data = {
      id: this.state.deleteId,
    };
    const userDelete_api_url =
      process.env.REACT_APP_API_URL + "/api/account/deleteAccount";
    await axios
      .post(userDelete_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            successError: "error",
          });
        } else {
          if (data.success) {
            // Get All data
            this.vendor_list("");
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              successError: "error",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  uploadBulkCsv = (event) => {
    this.setState({ bulkuploading: true });
    var commonConfig = { delimiter: "," };
    Papa.parse(event.target.files[0], {
      ...commonConfig,
      header: true,
      download: true,
      complete: (result) => {
        var temp = [];
        var finalArr = [];

        result.data.forEach(function myFunction(item, index) {
          temp.forEach(function myFunction(itemT, indexT) {
            if (
              itemT.Team?.trim() === item.Team?.trim() &&
              itemT.Username?.trim() === item.Username?.trim() &&
              item.Username !== "" &&
              item.Team !== ""
            ) {
              finalArr.push(item);
            }
          });
          temp.push(item);
        });

        if (finalArr.length > 0) {
          console.log("Duplicate available");
          this.setState({
            open: true,
            message:
              " There are some duplicate records in your file. Please remove and try again",
            successError: "error",
          });
        } else {
          const selectedFile = event.target.files[0];
          if (
            selectedFile.type === "application/vnd.ms-excel" ||
            selectedFile.type === "text/csv"
          ) {
            const formData = new FormData();
            formData.append("File", selectedFile);
            const bulk_api_url =
              process.env.REACT_APP_API_URL + "/api/account/addBulkCsv";
            axios
              .post(bulk_api_url, formData, {
                headers: {
                  authorization:
                    "Bearer " + Cookies.get("accessToken"),
                },
              })
              .then((response) => {
                const { data } = response;
                if (data.error) {
                  this.setState({
                    open: true,
                    message: data.message,
                    successError: "error",
                    bulkuploading: false,
                  });
                } else {
                  var message = "";
                  if (data.total > 0) {
                    message +=
                      "Total accounts in CSV: " + data.total + "<br />";
                  }
                  if (data.alreadyexist > 0) {
                    message +=
                      "Total existing accounts updated: " +
                      data.alreadyexist +
                      "<br />";
                  }
                  if (data.vbdaccountnotfound > 0) {
                    message +=
                      "Total invalid accounts: " +
                      data.vbdaccountnotfound +
                      "<br />";
                  }
                  if (data.totalaccsaveissue > 0) {
                    message +=
                      "Total accounts having issue in saving: " +
                      data.totalaccsaveissue +
                      "<br />";
                  }
                  if (data.totalsaved > 0) {
                    message +=
                      "Total newly created accounts: " +
                      data.totalsaved +
                      "<br />";
                  }
                  if (data.alreadyexist > 0) {
                    message +=
                      "<br/><p>Note: We have update the password of all the accounts with same email id.</p>";
                  }
                  this.setState({ bulkuploading: false, uploadBulkCsvDate: this.props.UserProfile.data.nextAddBulkCsv }, () => {
                    this.vendor_list(message);
                    this.props.getUserProfile();
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  open: true,
                  message: "Something went wrong, please try again",
                  successError: "error",
                  bulkuploading: false,
                });
              });
          } else {
            this.setState({
              open: true,
              message: "Only csv file allowed",
              successError: "error",
              bulkuploading: false,
            });
          }
        }
      },
    });
  };


  chooseAllVendorsForScan = (evtVal) => {
    if (evtVal) {
      var selectedVendors = [];
      var selectedCount = 0;
      document.querySelectorAll("tbody tr").forEach((row) => {
        // select the checkbox of each row
        var checkbox = row.querySelector("input[type='checkbox']");
        // get the id of the checkbox
        var id = checkbox.getAttribute("id");
        if (selectedCount < 5 && selectedVendors.length < 5) {
          // check the checkbox
          checkbox.checked = true;
          // push the id to the array
          selectedVendors.push(id);
          selectedCount++;
        } else if (selectedCount < 6 && selectedVendors.length < 6) {
          // uncheck the checkbox if more than 5 are already selected
          checkbox.checked = false;
          if (selectedCount < 5 && selectedVendors.length < 6) {
            this.setState({
              open: true,
              message: "You can select maximum 5 accounts at a time",
              successError: "error",
            });
          }
        }
      });
      this.setState({ scanvendors: selectedVendors });
    } else {
      this.setState({ scanvendors: [] });
    }
  };




  chooseVendorForScan = (item, evt, indexs) => {
    const { scanvendors } = this.state;
    if (evt.target.checked) {
      if (scanvendors.length !== 5 && scanvendors.length < 5) {
        scanvendors.push(item.id);
        this.setState({ scanvendors });
      } else {
        this.setState({
          open: true,
          message: "You can select maximum 5 accounts at a time",
          successError: "error",
        });
      }
    } else {
      if (scanvendors.length > 0) {
        var index = scanvendors.indexOf(item.id);
        scanvendors.splice(index, 1);
        if (scanvendors.length > 0) {
          this.setState({ scanvendors });
        } else {
          this.setState({ scanvendors: [] });
        }
      }
    }
  };


  scanVendorsSubmit = () => {
    const { syncvendors } = this.state;
    const data = {
      ids: syncvendors,
    };

    if (syncvendors.length > 0) {
      this.setState({ loader: true, syncshow: false }, () => {
        const selectedVendorEventScan_api_url =
          process.env.REACT_APP_API_URL +
          "/api/account/scanMultipleVendorEvent";
        axios
          .post(selectedVendorEventScan_api_url, data, {
            headers: {
              authorization: "Bearer " + Cookies.get("accessToken"),
            },
          })
          .then((response) => {
            const { data } = response;
            this.setState({
              scanvendors: [],
            });
            const message =
              data.total === 0
                ? "All accounts are synced recently please try after some time"
                : `Total ${data.total} ${data.message}`;

            var msgtype = data.total === 0 ? "error" : "success";
            this.vendor_list(message, msgtype);
          })
          .catch((error) => {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              successError: "error",
            });
          });
      });
    } else {
      this.setState({
        open: true,
        message: "Please select event to scan",
        alertcolor: "error",
      });
    }
  };
  refreshVendorsSubmit = () => {
    const { scanvendors } = this.state;
    const data = {
      ids: scanvendors,
    };

    if (scanvendors.length > 0) {
      this.setState({ loader: true, refreshshow: false }, () => {
        const selectedVendorEventScan_api_url =
          process.env.REACT_APP_API_URL +
          "/api/account/refreshMultipleVendorEvent";
        axios
          .post(selectedVendorEventScan_api_url, data, {
            headers: {
              authorization: "Bearer " + Cookies.get("accessToken"),
            },
          })
          .then((response) => {
            const { data } = response;
            this.setState({
              scanvendors: [],
            });
            const message =
              data.total === 0
                ? "All accounts refreshed recently please try after some time"
                : `Total ${data.total} ${data.message}`;

            var msgtype = data.total === 0 ? "error" : "success";
            this.vendor_list(message, msgtype);
          })
          .catch((error) => {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              successError: "error",
            });
          });
      });
    } else {
      this.setState({
        open: true,
        message: "Please select event to scan",
        alertcolor: "error",
      });
    }
  };

  filterchange = (event) => {
    const { filterData } = this.state;
    filterData[event.target.name] = event.target.value;
    this.setState({ filterData })
  };

  filterchangeUserName = (event) => {
    this.setState({ userfilter: event.target.value })
  };


  filterSearch = () => {
    const {
      vendorList,
      // filterData
    } = this.state;
    let newDataList = vendorList.data;
    var data = [];
    newDataList !== undefined
      ? (data = newDataList.map((data, index) => {
        if (data.account_type === "Vendor") {
          return {
            id: data._id,
            status: data.is_active,
            League: data.league === "" ? data.vendor : data.league,
            Team: data.team,
            UserName: data.username,
            Password: data.password,
            fetchingevent_scheduler: data.fetchingevent_scheduler,
            account_type: data.account_type,
            event:
              data.event !== "undefined"
                ? data.event
                : { status: "No Added Yet!" },
            nextRefresh: data.nextRefresh,
            nextScan: data.nextScan,
          };
        }
        return null;
      }))
      : console.log(data);
    const items = [];

    data
      .map((item) => item !== undefined && items.push(item))
      .filter((item) => item !== undefined);
    const ids = items.map((i) => { return i.id })
    this.setState({ syncvendors: ids })
    this.setState({ dataLists: items });
  };

  countSync = () => {
    const { syncvendors, dataLists } = this.state;
    // getting records from dataLists via syncvendors which have id
    const selectedVendors = dataLists.filter((item) =>
      syncvendors.includes(item.id)
    );
    // counting time for sync button clicked
    // compaere lastSync time with current time
    const compareTime = (nextSync) => {
      const nextSyncTime = new Date(nextSync).getTime();
      const currentTime = new Date().getTime();
      const diffSeconds = Math.round((nextSyncTime - currentTime) / 1000);
      return diffSeconds;
    };

    const counts = selectedVendors
      .map((item) => {
        const diffSeconds = compareTime(item.nextScan);
        if (diffSeconds < 0) {
          return item.id;
        }
        return null;
      })
      .filter((item) => item !== null);
    this.setState({ syncCount: counts.length });
  };

  countRefresh = () => {
    const { scanvendors, dataLists } = this.state;
    // getting records from dataLists via scanvendors which have id
    const selectedVendors = dataLists.filter((item) =>
      scanvendors.includes(item.id)
    );
    // counting time for sync button clicked
    // compaere lastSync time with current time
    const comparetime = (nextRefresh) => {
      const lastRefreshTime = new Date(nextRefresh).getTime();
      const currentTime = new Date().getTime();
      const diffSeconds = Math.floor((lastRefreshTime - currentTime) / 1000);
      return diffSeconds;
    };

    const counts = selectedVendors
      .map((item) => {
        const diffSeconds = comparetime(item.nextRefresh);
        // check if diffe
        if (diffSeconds < 0) {
          return item.id;
        }
        return null;
      })
      .filter((item) => item !== null);
    this.setState({ refreshCount: counts.length });
  };

  handleSelectSortByCreatedOnChange = (event) => {
    const sortByOrder = event.target.value;
    this.setState({
      sortBy: sortByOrder === "all" ? "" : "created",
      sortOrder: sortByOrder,
      isSortingLoader: true
    }, () => {
      this.vendor_list("");
    })
  };

  changepageLimit(event) {
    const { filterData } = this.state;
    filterData['limit'] = this.props.limit
    filterData[event.target.name] = parseInt(event.target.value);
    filterData["skip"] = 0;
    this.props.setLimit({
      pageName: "vendorList",
      pageLimit: parseInt(event.target.value),
    })
    this.setState({
      searchPage: 1,
      filterData,
      //  loader: true,
      isSortingLoader: true
    }, () => {
      this.vendor_list("");
    });
  }

  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };

  onPageChanged = (data) => {
    const { currentPage } = data;
    const { filterData } = this.state;
    filterData["skip"] = filterData.limit * (currentPage - 1);
    const skipquery = filterData.limit * (currentPage - 1);
    this.props.history.push(`/vendor-list?page=${skipquery}&searchPage=${currentPage}`);
    this.setState({
      filterData,
      // loader: true,
      isSortingLoader: true
    }, () => {
      this.vendor_list("");
    });
  }

  handleSubmit = () => {
    const { filterData } = this.state;
    filterData["skip"] = 0;
    this.setState(
      { searchPage: 1, filterData, loader: true },
      () => {
        this.vendor_list("");
      }
    );
  }

  /// sorting functaon
  async handleRotateIcon(columnname) {
    if (this.state.currentColumn === null) {
      await this.setState({ currentColumn: columnname });
    }

    if (this.state.currentColumn !== columnname) {
      await this.setState({
        activeasc: false,
        activedesc: false,
        currentColumn: columnname,
        styleInactive: false,
        direction: "",
      });
    }

    if (this.state.currentColumn === columnname) {
      if (
        !this.state.styleInactive &&
        !this.state.activeasc &&
        !this.state.activedesc &&
        this.state.currentColumn === columnname
      ) {
        this.customSorting({ column: columnname, asc: true });
        this.setState({
          styleInactive: true,
          currentColumn: columnname,
          direction: "asc",
        });
        console.log("1");
      }
      if (
        this.state.styleInactive &&
        !this.state.activeasc &&
        !this.state.activedesc &&
        this.state.currentColumn === columnname
      ) {
        this.customSorting({ column: columnname, asc: false });
        await this.setState({
          styleInactive: true,
          activeasc: true,
          activedesc: false,
          direction: "desc",
        });
      } else if (
        this.state.styleInactive &&
        this.state.activeasc &&
        !this.state.activedesc &&
        this.state.currentColumn === columnname
      ) {
        this.customSorting({ column: columnname, asc: true });
        await this.setState({
          currentColumn: columnname,
          activeasc: false,
          activedesc: false,
          direction: "asc",
        });
        console.log("3");
      }
    }
  }


  customSorting = (obj) => {
    if (obj) {
      if (obj.column) {
        const { filterData } = this.state;
        filterData["skip"] = 0;
        this.setState(
          {
            filterData,
            loader: true,
            sortBy: obj.column,
            sortOrder: obj.asc ? "asc" : "desc",
          },
          () => {
            this.vendor_list("");
          }
        );
      }
    }
  };

  apicall = () => {
    console.log('CSV Timer start')
  }

  render() {
    const {
      hidepassword,
      indexNum,
      dataLists,
      modalShow,
      loadpass,
      totalRecords,
      scanvendors,
    } = this.state;

    var today = new Date();
    today.setDate(today.getDate() - 2);

    const actionbutton = {
      backgroundColor: "#002986!important",
      color: "white!important",
      padding: "2px 5px!important",
      textTransform: "none!important",
      fontWeight: "bold",
      fontFamily: "Barlow!important",
      margin: "0 5px",
    };


    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Vendor List</title>
          </Helmet>
          <Header />

          <div className="c-app c-default-layout user-dashboard-body vendorlist">
            <Usersidebar />
            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  {/* {this.state.loader && <PageLoader />} */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="space-top-col">
                      <Card
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          color: "#000059!important",
                          textAlign: "left",
                          padding: "30px",
                          backgroundColor: "#fbfbfb;",
                        }}
                      >
                        <div className="vendortable-header">
                          <Typography
                            variant="h6"
                            sx={{
                              width: "100%",
                              maxWidth: "14rem",
                              color: "#000059!important",
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: "1.6rem",
                              fontFamily: "Barlow!important",
                            }}
                          >
                            {" "}
                            Vendor Accounts
                          </Typography>

                          <Grid
                            container
                            spacing={{ xs: 1, md: 1, lg: 1 }}
                            columns={{ xs: 4, sm: 8, md: 8 }}
                            className="button-align"
                          >
                            <Grid item>
                              <Button
                                component="a"
                                size="large"
                                variant="contained"
                                sx={{
                                  backgroundColor: "#002986!important",
                                  color: "white!important",
                                  textTransform: "none!important",
                                  fontWeight: "bold",
                                  fontFamily: "Barlow!important",
                                }}

                                href={require("images/Bulk-Vendor-CSV-Sample.csv")}
                                download="Sample.csv"
                                startIcon={<DownloadIcon />}
                                to="/add-vendor"
                              >
                                Download Sample CSV
                              </Button>
                            </Grid>

                            <Grid item>
                              <input
                                style={{ display: "none" }}
                                id="actual-csvuploader"
                                type="file"
                                onChange={this.uploadBulkCsv}
                              />
                              {/* <Button
                                component="label"
                                htmlFor="actual-csvuploader"
                                size="large"
                                variant="contained"
                                sx={{
                                  backgroundColor: "#002986!important",
                                  color: "white!important",
                                  textTransform: "none!important",
                                  fontWeight: "bold",
                                  fontFamily: "Barlow!important",
                                }}
                                startIcon={<UploadFileIcon />}
                              >
                                {" "}
                                {this.state.bulkuploading
                                  ? "Uploading"
                                  : "Upload CSV"}
                              </Button> */}
                              {' '}
                              <UploadCSVTimerButton
                                bulkuploading={this.state.bulkuploading}
                                component="label"
                                htmlFor="actual-csvuploader"
                                apiCall={this.apicall}
                                date={this.props.UserProfile.data.nextAddBulkCsv}
                                item={this.props.UserProfile.data._id}
                                id={`uploadCsvTimer-${this.props.UserProfile.data._id}`}
                                name="Upload CSV"
                                time={0}
                                size="large"
                                timeinseconds={0}
                                buttonType='uploadcsv'
                              />
                            </Grid>
                            <Grid item>
                              <Button
                                component={Link}
                                size="large"
                                variant="contained"
                                sx={{
                                  backgroundColor: "#002986!important",
                                  color: "white!important",
                                  textTransform: "none!important",
                                  fontWeight: "bold",
                                  fontFamily: "Barlow!important",
                                }}
                                startIcon={<AddIcon />}
                                to="/add-vendor"
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </div>

                        <ValidatorForm
                          onSubmit={this.handleSubmit}
                          className="filter-form mb-5"
                          autoComplete="off"
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                textAlign: "left",
                                marginTop: "5px",
                                marginBottom: "20px",
                              }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              <Grid item xs={12} sm={4} md={2.4} className="Vendor-input">
                                <label>League</label>
                                <TextField
                                  variant="outlined"
                                  placeholder="League"
                                  size="small"
                                  value={this.state.filterData.league}
                                  name="league"
                                  style={{ width: "100%", marginTop: "3px" }}
                                  onChange={(e) => {
                                    this.filterchange(e);
                                  }}
                                  autoComplete="off"
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={2.4} className="Vendor-input2">
                                <label>Team</label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  placeholder="Team"
                                  value={this.state.filterData.team}
                                  name="team"
                                  autoComplete="off"
                                  style={{
                                    width: "100%",
                                    marginTop: "3px",
                                    marginLeft: "2px",
                                  }}
                                  onChange={(e) => {
                                    this.filterchange(e);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={2.4} className="Vendor-input1">
                                <label>Username</label>
                                <TextValidator
                                 sx={{
                                    '& .MuiOutlinedInput-input': {
                                     '&:-webkit-autofill': {
                                        WebkitBoxShadow: '0 0 0 0 inset',
                                     },
                                    },
                                 }}
                                 id={`userfilter-${Math?.random()?.toString(36)?.substring(7)}`} // Randomized ID
                                 name={`userfilter-${Math?.random()?.toString(36)?.substring(7)}`} // Randomized Name
                                 variant="outlined"
                                 placeholder="Username"
                                 size="small"
                                 autoComplete="off"
                                 value={this.state.userfilter ?? ""}
                                 style={{ width: "100%", marginTop: "3px" }}
                                 onChange={(e) => this.filterchangeUserName(e)}
                                 inputProps={{
                                    autoComplete: 'new-password', // Prevent autofill
                                 }}
                                /> 
                              </Grid>
                              <Grid item xs={12} sm={4} md={2.4} className="Vendor-input">
                                <FormControl fullWidth>
                                  <label>Status</label>
                                  <Select
                                    autoComplete="off"
                                    sx={{ position: 'relative', top: '3px' }}
                                    name="status"
                                    onChange={(e) => {
                                      this.filterchange(e);
                                    }}
                                    placeholder="Status"
                                    size="small"
                                    defaultValue="all"
                                  >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="error">Error</MenuItem>
                                    <MenuItem value="no_error">No Error</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={4} md={2.4} sx={{ position: 'relative', top: '36px' }} >
                                <Button
                                  variant="contained"
                                  type="submit"
                                  className="filter-btn"
                                  startIcon={<HiOutlineSearch />}
                                >
                                  Search
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </ValidatorForm>
                        {!this.state.loader ? (
                          <>
                            <CardContent
                              sx={{
                                padding: "0px!important",
                                marginTop: "10px!important",
                              }}
                              className="plan-tbl vendor-outer"
                            >
                              <Grid
                                container
                                spacing={{ xs: 1, md: 1, lg: 1 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                                className="button-align"
                              >
                                <Grid item xs={12} sm={4} md={4}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      marginBottom: "10px",
                                      lineHeight: "38px",
                                      alignItems: "end"
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: "4px 10px 10px 10px",
                                        borderRadius: "4px",
                                        width: "100%",
                                        maxWidth: "17.5rem",
                                        display: "flex",
                                        justifyContent: "end",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        boxShadow:
                                          "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                                      }}
                                    >
                                      <Typography
                                        variant="p"
                                        gutterBottom
                                        sx={{
                                          width: "100%",
                                          fontSize: "18px",
                                          fontWeight: "600",
                                          color: "#000000",
                                          textAlign: "center",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        Bulk Actions
                                      </Typography>
                                      <div
                                        style={{
                                          textAlign: "left",
                                          width: "100%",
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          sx={{
                                            fontWeight: "semi-bold",
                                            marginRight: "10px",
                                          }}
                                          onClick={() =>
                                            this.chooseAllVendorsForScan(
                                              scanvendors.length > 0 ? false : true
                                            )
                                          }
                                        >
                                          {scanvendors.length > 0
                                            ? "Un-Select All"
                                            : "Select All"}
                                        </Button>
                                        {scanvendors.length > 0 && (
                                          <>
                                            <Button
                                              variant="contained"
                                              onClick={() => {
                                                this.setState({ refreshshow: true });
                                                this.countRefresh();
                                              }}
                                            >
                                              Refresh selected
                                            </Button>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid className="grid-div filterBox" item sx={{ margin: "0 0 0 auto", marginBottom: "10px" }}>
                                  <div
                                    className="grid-div-inner"
                                  >
                                    <FormControl
                                      sx={{
                                        minWidth: 165,
                                        backgroundColor: '#FBFBFB !important',
                                      }}
                                      size="small"
                                      className="form-btn-sync"
                                    >
                                      <InputLabel id="demo-select-small-label">Sort By</InputLabel>
                                      <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label="sortOrder"
                                        value={this.state.sortOrder}
                                        name="sortOrder"
                                        onChange={(e) => this.handleSelectSortByCreatedOnChange(e)}
                                      >
                                        <MenuItem value="all">Default</MenuItem>
                                        <MenuItem value="desc">Newest to Oldest</MenuItem>
                                        <MenuItem value="asc">Oldest to Newest</MenuItem>
                                      </Select>
                                    </FormControl>
                                    &nbsp;&nbsp;
                                    <Button
                                      variant="outlined"
                                      align="right"
                                      className="button-sync-all-vendorlist"
                                      onClick={() => {
                                        this.setState({ syncshow: true });
                                        this.countSync();
                                        this.vendor_list('');
                                      }}
                                    >
                                      Sync All
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                              {/* Table */}
                              {!this.state.isSortingLoader ?
                                <>
                                  <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 900 }} aria-label="customized table">
                                      <TableHead>
                                        {dataLists?.length > 0 ? (
                                          <TableRow>
                                            <StyledTableCell sx={{ cursor: "pointer" }}>
                                              Select
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              <TableSortLabel
                                                IconComponent={HiOutlineArrowNarrowDown}
                                                active={
                                                  this.state.currentColumn === "league"
                                                }
                                                direction={
                                                  this.state.currentColumn === "league"
                                                    ? this.state.direction
                                                    : "asc"
                                                }
                                                onClick={() => this.handleRotateIcon("league")}
                                              >
                                                League
                                              </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              <TableSortLabel
                                                IconComponent={HiOutlineArrowNarrowDown}
                                                active={
                                                  this.state.currentColumn === "team"
                                                }
                                                direction={
                                                  this.state.currentColumn === "team"
                                                    ? this.state.direction
                                                    : "asc"
                                                }
                                                onClick={() => this.handleRotateIcon("team")}
                                              >
                                                Team
                                              </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              <TableSortLabel
                                                IconComponent={HiOutlineArrowNarrowDown}
                                                active={
                                                  this.state.currentColumn === "username"
                                                }
                                                direction={
                                                  this.state.currentColumn === "username"
                                                    ? this.state.direction
                                                    : "asc"
                                                }
                                                onClick={() => this.handleRotateIcon("username")}
                                              >
                                                UserName
                                              </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              Password
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              Status
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              <div
                                                style={{
                                                  width: "100%",
                                                  minWidth: "max-content",
                                                }}
                                              >
                                                Account Settings
                                              </div>
                                            </StyledTableCell>
                                          </TableRow>
                                        ) : (
                                          <TableRow>
                                            <StyledTableCell sx={{ cursor: "pointer" }}>
                                              Select
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              League
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              Team
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              UserName
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              Password
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              Status
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              <div
                                                style={{ width: "100%", minWidth: "max-content", }}
                                              >
                                                Account Settings
                                              </div>
                                            </StyledTableCell>
                                          </TableRow>
                                        )}
                                      </TableHead>
                                      <TableBody>
                                        <Backdrop
                                          sx={{
                                            color: "#fff",
                                            backgroundColor:
                                              "rgba(255, 255, 255, 0.72)",
                                            position: "absolute!important",
                                            zIndex: (theme) =>
                                              theme.zIndex.drawer + 1,
                                          }}
                                          open={this.state.loader}
                                        ></Backdrop>
                                        {dataLists?.length > 0 ? (
                                          <>
                                            {dataLists && dataLists?.map((item, index) => (
                                              <StyledTableRow
                                                key={index}
                                              >
                                                <StyledTableCell component="td" scope="row">
                                                  <div className="flex">
                                                    <Checkbox
                                                      id={item?.id}
                                                      checked={scanvendors?.includes(item?.id)}
                                                      onChange={(evt) =>
                                                        this.chooseVendorForScan(item, evt, index)
                                                      }
                                                      inputProps={{
                                                        "aria-label": "primary checkbox",
                                                      }}
                                                    />
                                                  </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                  component="td"
                                                  scope="row"
                                                >
                                                  {item?.League}
                                                </StyledTableCell>
                                                <StyledTableCell component="td" scope="row"
                                                >
                                                  {item?.Team}
                                                </StyledTableCell>
                                                <StyledTableCell component="td" scope="row">
                                                  {item?.UserName}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      minWidth: "max-content",
                                                    }}
                                                  >
                                                    <div className="flex">
                                                      <div className="flex">
                                                        <div
                                                          style={{
                                                            width: "fit-content",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            flex: "wrap",
                                                            wordBreak: "break-all",
                                                          }}
                                                        >
                                                          {indexNum === item?.id ? (
                                                            <>
                                                              {loadpass ? (
                                                                <img src={LoadPassword} alt="" />
                                                              ) : (
                                                                <>
                                                                  {hidepassword
                                                                    ? item?.password
                                                                    : "*******"}
                                                                </>
                                                              )}
                                                              <VisibilityOffIcon
                                                                style={{
                                                                  cursor: "pointer",
                                                                  fontSize: "1.3rem",
                                                                  color: "#002986",
                                                                }}
                                                                onClick={() =>
                                                                  this.hiddenPassword(item)
                                                                }
                                                              />
                                                            </>
                                                          ) : (
                                                            <>
                                                              <HiDotsHorizontal
                                                                style={{
                                                                  margin: "0px",
                                                                  padding: "0px",
                                                                  position: "relative",
                                                                  left: "2px",
                                                                  fontSize: "30px",
                                                                }}
                                                              />
                                                              <HiDotsHorizontal
                                                                style={{ fontSize: "30px" }}
                                                              />
                                                              <VisibilityIcon
                                                                style={{
                                                                  cursor: "pointer",
                                                                  fontSize: "1.3rem",
                                                                  color: "#002986",
                                                                }}
                                                                onClick={() =>
                                                                  this.hiddenPassword(item)
                                                                }
                                                              />
                                                            </>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                  component="td"
                                                  scope="row"
                                                >
                                                  <div className="container-tablecell">
                                                    {item?.event.status.length > 36 ? (
                                                      <section>
                                                        <div class="outer-wrapper">
                                                          <div class="inner-wrapper">
                                                            {item?.account_type === "Vendor" ? (
                                                              <>
                                                                {item.event && (
                                                                  <span>
                                                                    {item?.event.status.includes(
                                                                      "Scan Completed: Events"
                                                                    ) &&
                                                                      item.fetchingevent_scheduler !==
                                                                      "finished" ? (
                                                                      <>
                                                                        Scan Completed: loading events
                                                                      </>
                                                                    ) : (
                                                                      <>{item?.event.status}</>
                                                                    )}
                                                                  </span>
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>
                                                                {item?.status === 3 ? (
                                                                  <img
                                                                    src={Warning}
                                                                    className="table-data-icon"
                                                                    alt="Warning"
                                                                  />
                                                                ) : item?.status === 2 ? (
                                                                  <img
                                                                    src={Warningyellow}
                                                                    className="table-data-icon"
                                                                    alt="Warning"
                                                                  />
                                                                ) : item?.status === 1 ? (
                                                                  <img
                                                                    src={Tickicon}
                                                                    className="table-data-icon"
                                                                    alt="Warning"
                                                                  />
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </section>
                                                    ) : (
                                                      <div class="outer-wrapper" style={{ width: '260px' }}>
                                                        {item?.account_type === "Vendor" ? (
                                                          <>
                                                            {item.event && (
                                                              <span>
                                                                {item?.event.status.includes(
                                                                  "Scan Completed: Events"
                                                                ) &&
                                                                  item.fetchingevent_scheduler !==
                                                                  "finished" ? (
                                                                  <>
                                                                    Scan Completed: loading events
                                                                  </>
                                                                ) : (
                                                                  <>{item?.event.status}</>
                                                                )}
                                                              </span>
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {item?.status === 3 ? (
                                                              <img
                                                                src={Warning}
                                                                className="table-data-icon"
                                                                alt="Warning"
                                                              />
                                                            ) : item?.status === 2 ? (
                                                              <img
                                                                src={Warningyellow}
                                                                className="table-data-icon"
                                                                alt="Warning"
                                                              />
                                                            ) : item?.status === 1 ? (
                                                              <img
                                                                src={Tickicon}
                                                                className="table-data-icon"
                                                                alt="Warning"
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                          </>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </StyledTableCell>
                                                <StyledTableCell component="td" scope="row">
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                    }}
                                                  >
                                                    <Button
                                                      component={Link}
                                                      size="small"
                                                      variant="contained"
                                                      sx={actionbutton}
                                                      to={`/vendor-events/${item.id}`}
                                                    >
                                                      View
                                                    </Button>

                                                    <Button
                                                      component={Link}
                                                      size="small"
                                                      variant="contained"
                                                      sx={actionbutton}
                                                      to={`/edit-vendor/${item.id}`}
                                                    >
                                                      Edit
                                                    </Button>

                                                    <TimerButton
                                                      apiCall={this.scanAPiCall}
                                                      date={item.nextScan}
                                                      item={item}
                                                      id={`scan-${item.id}`}
                                                      name="Sync"
                                                      time={0}
                                                      timeinseconds={0}
                                                    />

                                                    <TimerButton
                                                      apiCall={this.refreshAPiCall}
                                                      date={item.nextRefresh}
                                                      item={item}
                                                      id={`refresh-${item.id}`}
                                                      name="Refresh"
                                                      time={0}
                                                      timeinseconds={0}
                                                    />
                                                    <Button
                                                      type="button"
                                                      size="small"
                                                      variant="contained"
                                                      sx={actionbutton}
                                                      onClick={() => this.setModalShow(item.id)}
                                                    >
                                                      Delete
                                                    </Button>
                                                    <Button
                                                      size="small"
                                                      variant="contained"
                                                      sx={actionbutton}
                                                      component={Link}
                                                      to={`/account-logs/${item.id}`}
                                                    >
                                                      Logs
                                                    </Button>
                                                  </div>
                                                </StyledTableCell>
                                              </StyledTableRow>
                                            ))}
                                          </>) : (
                                          <>
                                            <StyledTableRow>
                                              <td
                                                colSpan={13}
                                                style={{ padding: "2.6rem 1rem" }}
                                              >
                                                <Typography
                                                  variant="h6"
                                                  sx={{
                                                    fontSize: "1.6rem",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  No Records Found
                                                </Typography>
                                              </td>
                                            </StyledTableRow>
                                          </>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </>
                                :
                                <ContainerLoader />
                              }
                              <br />
                            </CardContent>
                            {/* end table */}
                          </>
                        ) : (
                          <ContainerLoader />
                        )}
                        {dataLists?.length > 0 ? (
                          <>
                            {totalRecords > 0 && (
                              <Pagination
                                key="paginationnew"
                                searchPage={this.state.searchPage}
                                SearchDisable={this.SearchDisable}
                                totalRecords={totalRecords}
                                pageLimit={this.state.filterData.limit}
                                onPageChanged={this.onPageChanged}
                              />
                            )}

                            {totalRecords > 0 && (
                              <div
                                style={{
                                  textAlign: "right",
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "center",
                                }}
                              >
                                <label>Items per page :</label>
                                <FormControl
                                  sx={{
                                    m: 1,
                                    minWidth: 50,
                                  }}
                                  size="small"
                                >
                                  <Select
                                    autoComplete="off"
                                    defaultValue={this.state.filterData.limit}
                                    value={this.state.filterData.limit}
                                    name="limit"
                                    sx={{
                                      "& .MuiSelect-select": {
                                        padding: "4px 14px",
                                        fontFamily: "Barlow",
                                      },
                                    }}
                                    onChange={(e) => this.changepageLimit(e)}
                                  >
                                    <MenuItem value="5">5</MenuItem>
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="15">15</MenuItem>
                                    <MenuItem value="20">20</MenuItem>
                                    <MenuItem value="25">25</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            )}
                          </>) : (null)}
                        <Dialog
                          maxWidth="sm"
                          open={modalShow}
                          onClose={this.onHide}
                        >
                          <DialogTitle>
                            <Typography
                              sx={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                              }}
                            >
                              Delete
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <IconButton
                              aria-label="close"
                              onClick={this.onHide}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <DialogContentText>
                              Are you sure you want to Delete, it can cause
                              all the links generated from that account to
                              become invalid ?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={this.onHide}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={this.callDelete}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              Delete
                            </Button>
                          </DialogActions>
                        </Dialog>
                        {/* Sync Dialog */}
                        <Dialog
                          maxWidth="sm"
                          open={this.state.syncshow}
                          onClose={this.onHideSync}
                          sx={{
                            "& .MuiDialog-paper": {
                              width: "100%",
                            },
                          }}
                        >
                          <DialogTitle>Sync</DialogTitle>
                          <DialogContent>
                            <IconButton
                              aria-label="close"
                              onClick={this.onHideSync}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <DialogContentText>
                              Are you sure you want to sync{" "}
                              {this.state.syncCount} accounts ?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={this.onHideSync}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => this.scanVendorsSubmit()}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              Sync
                            </Button>
                          </DialogActions>
                        </Dialog>
                        {/* Refresh Dialog */}
                        <Dialog
                          maxWidth="sm"
                          open={this.state.refreshshow}
                          onClose={this.onHideRefresh}
                          sx={{
                            "& .MuiDialog-paper": {
                              width: "100%",
                            },
                          }}
                        >
                          <DialogTitle>Refresh</DialogTitle>
                          <DialogContent>
                            <IconButton
                              aria-label="close"
                              onClick={this.onHideRefresh}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <DialogContentText>
                              Are you sure you want to refresh{" "}
                              {this.state.refreshCount} accounts ?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={this.onHideRefresh}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => this.refreshVendorsSubmit()}
                              sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                              }}
                            >
                              Refresh
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Card>
                    </Grid>
                  </Grid>
                </main>
                <AlertMessage
                  key={`alertbtn`}
                  open={this.state.open}
                  message={this.state.message}
                  severity={this.state.successError}
                  closeCall={this.handleClose}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div >
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
    limit: state.Page.vendorList,
    scanTime: state.timerDuration.ScanEventTime,
    refreshTime: state.timerDuration.RefreshAccountTime,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
    setLimit: (data) => dispatch(SetPageLimt(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Vendorlist);
